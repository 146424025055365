import { ITypeProducts } from '../types/productsTypes';

export const DataProducts: ITypeProducts = [
  {
    title: 'Проступь накладная с подступенком',
    img: {
      0: 'step_graphiteGrey.png',
      1: 'step_concrete.png',
      2: 'step_brownChocolate.png',
      3: 'step_brown-red.png',
      4: 'step_GreenMoss.png',
      5: 'step_anthracite.png',
      6: 'step_marble.png',
    },

    size: ['1200 / 350 / 40', '1200 / 400 / 40', '1360 / 400 / 40'],
    colorName: {
      0: 'Графитовый серый',
      1: 'Бетон / RAL 7040',
      2: 'Коричневый шоколад',
      3: 'Коричнево-красный',
      4: 'Зелёный мох RAL 6005',
      5: 'Антрацит',
      6: 'Мрамор',
    },
    color: [
      '#45494E',
      '#989EA1',
      '#442F29',
      '#792423',
      '#114232',
      '#282D3B',
      'color_marble.png',
    ],
    textureName: { 0: 'Гладкая', 1: 'Шагрень', 2: 'Дерево', 3: 'Черепашка' },
    texture: [
      '#EAEAEA',
      'texture_shagreen.jpg',
      'texture_wood.png',
      'texture_turtle.png',
    ],
    price: { 0: '72 BYN', 1: '78 BYN', 2: '82 BYN' },
    priceDescription: 'за комплект проступь+подступенок',
    info: 'Доступны к заказу иные цвета по каталогу RAL',
  },

  {
    title: 'Плитка',
    img: {
      0: 'tile_graphiteGrey.png',
      1: 'tile_concrete.png',
      2: 'tile_brownChocolate.png',
      3: 'tile_brown-red.png',
      4: 'tile_GreenMoss.png',
      5: 'tile_anthracite.png',
      6: 'tile_marble.png',
    },
    size: ['300 / 300 / 30', '400 / 400 / 40'],
    colorName: {
      0: 'Графитовый серый',
      1: 'Бетон / RAL 7040',
      2: 'Коричневый шоколад',
      3: 'Коричнево-красный',
      4: 'Зелёный мох RAL 6005',
      5: 'Антрацит',
      6: 'Мрамор',
    },
    color: [
      '#45494E',
      '#989EA1',
      '#442F29',
      '#792423',
      '#114232',
      '#282D3B',
      'color_marble.png',
    ],

    textureName: { 0: 'Шагрень', 1: 'Черепашка' },
    texture: ['texture_shagreen.jpg', 'texture_turtle.png'],
    price: { 0: '58 BYN', 1: '78 BYN' },
    priceDescription: 'за м2',
    info: 'Доступны к заказу иные цвета по каталогу RAL',
  },

  {
    title: 'Парапетные плиты',
    img: {
      0: 'parapet_graphiteGrey.png',
      1: 'parapet_concrete.png',
      2: 'parapet_brownChocolate.png',
      3: 'parapet_brown-red.png',
      4: 'parapet_GreenMoss.png',
      5: 'parapet_anthracite.png',
      6: 'parapet_marble.png',
    },
    size: [
      '1210 / 250 / 35',
      '1210 / 370 / 35',
      '1210 / 330 / 35',
      '1210 / 470 / 35',
      '1000 / 450 / 70 (округлая)',
    ],
    colorName: {
      0: 'Графитовый серый',
      1: 'Бетон / RAL 7040',
      2: 'Коричневый шоколад',
      3: 'Коричнево-красный',
      4: 'Зелёный мох RAL 6005',
      5: 'Антрацит',
      6: 'Мрамор',
    },
    color: [
      '#45494E',
      '#989EA1',
      '#442F29',
      '#792423',
      '#114232',
      '#282D3B',
      'color_marble.png',
    ],

    textureName: { 0: 'Гладкая' },
    texture: ['#EAEAEA'],
    price: { 0: '37 BYN', 1: '49 BYN', 2: '47 BYN', 3: '62 BYN', 4: '79 BYN' },
    priceDescription: 'за шт.',
    info: 'Доступны к заказу иные цвета по каталогу RAL',
  },

  {
    title: 'Широкоформатные плиты',
    img: { 0: 'titlePolished.png' },
    size: ['1200 / 400 / 40', '800 / 400 / 40', '600 / 300 / 40'],
    colorName: {
      0: 'Бетон / RAL 7040',
    },
    color: ['#989EA1'],
    textureName: { 0: 'Гладкая' },
    texture: ['#EAEAEA'],
    price: { 0: '64 BYN', 1: '44 BYN', 2: '34 BYN' },
    priceDescription: 'за шт. (производится под заказ)',
    info: 'Доступны к заказу иные цвета по каталогу RAL',
  },

  {
    title: 'Плитка “Доска состаренная”',
    img: { 0: 'board.png' },
    size: ['185 / 185 / 35', '59 / 185 / 35', '790 / 185 / 35'],
    colorName: {
      0: 'По согласованию',
    },
    color: [''],
    textureName: { 0: 'Дерево' },
    texture: ['texture_wood.png'],
    price: { 0: '82 BYN*', 1: '82 BYN*', 2: '82 BYN*' },
    priceDescription: 'за м2 (производится под заказ)',
    info: '',
  },
];
