import React from 'react';
import styles from './CustomContactLink.module.css';

interface ICustomContactLink {
  marginBottom?: number;
  src: string;
  alt: string;
  text: string;
}

const CustomContactLink = ({
  marginBottom,
  src,
  alt,
  text,
}: ICustomContactLink) => {
  return (
    <div
      className={styles.customContactLink}
      style={{ marginBottom: `${marginBottom}rem` }}
    >
      {src ? (
        <img
          className={styles.customContactLink_img}
          src={`/assets/images/${src}`}
          alt={alt}
        />
      ) : null}

      <p>{text}</p>
    </div>
  );
};

export default CustomContactLink;
