export const DataImgForCarousel: string[] = [
  'carousel_img_1.jpg',
  'carousel_img_2.jpg',
  'carousel_img_3.jpg',
  'carousel_img_4.jpg',
  'carousel_img_5.jpg',
  'carousel_img_6.jpg',
  'carousel_img_7.jpg',
  'carousel_img_8.jpg',
  'carousel_img_9.jpg',
  'carousel_img_10.jpg',
  'carousel_img_11.jpg',
  'carousel_img_12.jpg',
  'carousel_img_13.jpg',
  'carousel_img_14.jpg',
  'carousel_img_15.jpg',
  'carousel_img_16.jpg',
  'carousel_img_17.jpg',
  'carousel_img_18.jpg',
  'carousel_img_19.jpg',
  'carousel_img_20.jpg',
  'carousel_img_21.jpg',
  'carousel_img_22.jpg',
  'carousel_img_23.jpg',
  'carousel_img_24.jpg',
  'carousel_img_25.jpg',
  'carousel_img_26.jpg',
  'carousel_img_27.jpg',
  'carousel_img_28.jpg',
  'carousel_img_29.jpg',
  'carousel_img_30.jpg',
  'carousel_img_31.jpg',
];

export const DataDocForCarousel: string[] = [
  'carousel_doc_1.png',
  'carousel_doc_2.png',
  'carousel_doc_3.png',
  'carousel_doc_4.png',
  'carousel_doc_5.png',
  'carousel_doc_6.png',
  'carousel_doc_7.png',
  'carousel_doc_8.png',
  'carousel_doc_9.png',
  'carousel_doc_10.png',
];
