import React from 'react';
import './Slider.css';
import Container from '../common/Container/Container';
import SliderSlick from 'react-slick';

interface ISlider {
  images: string[];
}

const Slider = ({ images }: ISlider) => {
  const settings = {
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    dotsClass: 'slick-dots',
    nextArrow: (
      <div>
        <img src='/assets/images/arrow_right.png' alt='arrow_left' />
      </div>
    ),

    prevArrow: (
      <div>
        <img src='/assets/images/arrow_left.png' alt='arrow_left' />
      </div>
    ),
    dots: true,

    customPaging: function (i: number) {
      return (
        <div>
          <img src={`/assets/images/carousel_img_${i + 1}.jpg`} alt='icon' />
        </div>
      );
    },
  };

  return (
    <div className='slider'>
      <Container borderBottom={true}>
        <div className='slider_content'>
          <h2>Наши реализованные проекты</h2>

          <SliderSlick {...settings}>
            {images.map((item, index) => {
              return (
                <img key={index} src={`/assets/images/${item}`} alt='slide' />
              );
            })}
          </SliderSlick>
        </div>
      </Container>
    </div>
  );
};

export default Slider;
